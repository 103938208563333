<template>
    <v-container fluid fill-height class="backcolor" style="height: 255px">
        <v-layout child-flex row fill-height align-center class="mb-0 pb-0">
            <!-- Horizontal: text-xs-center -->
            <v-flex xs3 align-self-start class="hidden-sm-and-down">
                <v-container fluid fill-height mt-2 ml-5>
                    <v-row align="start" style="height: 200px;">
                        <v-img alt="TurismoBC Logo" class="shrink mt-1" contain max-width="200" max-height="200"
                               :src="company.logo_url != null ? company.logo_url : require('@/assets/images/no-image.jpg')"
                               :lazy-src="require('@/assets/images/no-image.jpg')"
                               width="200"/>
                    </v-row>
                    <v-row align="end">
                        <span class="text-body-2 white--text">
                            Desarrollado por <a href="https://www.idoogroup.com" target="_blank">idooGROUP</a>
                        </span>
                    </v-row>
                </v-container>
            </v-flex>
            <v-flex xs3 text-xs-center align-self-start class="hidden-sm-and-down">
                <v-container fluid fill-height mt-5>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/hospitals-clinics">
                            <span>Hospitales y Clínicas</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/medical-services">
                            <!-- #services -->
                            <span>Servicios Médicos</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/restaurants-leisures">
                            <!-- #Restaurant -->
                            <span>Restaurantes y Entretenimientos</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/hotels">
                            <!-- #Leisure -->
                            <span>Hoteles</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/other-services">
                            <!-- #Leisure -->
                            <span>Otros Servicios</span>
                        </v-btn>
                    </v-row>
                </v-container>
            </v-flex>
            <v-flex xs3 align-self-start class="hidden-sm-and-down">
                <v-container fluid fill-height mt-5>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/about">
                            <!-- #aboutas -->
                            <span>Nosotros</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/jointobhc">
                            <!-- #jointobhc -->
                            <span>Únete a BHC</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/contacts">
                            <!-- #contacts -->
                            <span>Contactos</span>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-btn transparent text class="ma-1" color="white" plain to="/blogs">
                            <!-- #blogs -->
                            <span>Blog</span>
                        </v-btn>
                    </v-row>
                </v-container>
            </v-flex>
            <v-flex align-self-start>
                <v-container grid-list-lg fill-height mt-5 md-5>
                    <v-layout column wrap fill-height>
                        <v-flex>
                            <v-icon color="blue">mdi-email</v-icon>
                            <span class="text-body-2 white--text"
                                  style="margin-left: 5px">{{ company.email }}</span>
                        </v-flex>
                        <v-flex>
                            <v-icon color="blue">mdi-phone</v-icon>
                            <span class="text-body-2 white--text text-wrap" style="margin-left: 5px">
                                {{ company.phone1 | formatPhone('MX') }}, <br/> {{ company.phone2 | formatPhone('US') }}
                            </span>
                        </v-flex>
                        <v-flex>
                            <div class="white--text" style="width: 100px">
                                <v-row align="start" justify="space-between">
                                    <v-col>
                                        <div v-if="company.facebook_profile != null">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="blue" dark v-bind="attrs" v-on="on" icon
                                                           :href="company.facebook_profile" target="_blank">
                                                        <v-icon size="24px">mdi-facebook</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Facebook</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-if="company.instagram_profile != null">
                                            <v-tooltip top v-if="company.instagram_profile != null">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="blue" dark v-bind="attrs" v-on="on" icon
                                                           :href="company.instagram_profile" target="_blank">
                                                        <v-icon size="24px">mdi-instagram</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Instagram</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-flex>
                        <v-flex>
                            <span class="text-body-2 white--text" style="margin-left: 5px">
                                &copy;2022. Todos los Derechos Reservados.
                            </span>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import companyService from "@/providers/CompanyService";
    
    export default {
        name: "FooterComponent",
        data: () => ({
            company: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                logo_url: ""
            },
        }),
        filters: {
            formatPhone(phn, country) {
                // console.log('phn:', phn);
                if (phn == 'undefined' || phn == null || phn === '') {
                    return country;
                }
                switch(country) {
                    case "US":
                        return "USA +1 (" + phn.substring(0,3) + ") " + phn.substring(3,6) + "-" + phn.substring(6);
                    case "MX":
                        return "MEX +52 (" + phn.substring(0,3) + ") " + phn.substring(3,6) + "-" + phn.substring(6);
                    default:
                        return country;
                }
            },
        },
        methods: {
            getCompany() {
                companyService.getViewData().then(record => {
                    // console.log("Record for Company: ", record.value);
                    if (record.value !== null) {
                        this.company = record.value;
                        // console.log("company: ", this.company);
                    }
                });
            }
        },
        beforeMount() {
            this.getCompany();
        }
    };
</script>

<style scoped>
    .backcolor {
        background-color: rgba(21, 27, 41, 1);
    }
    .v-btn {
        text-transform: none !important;
    }
</style>
